/*! angular-google-plus - v0.1.3 2015-08-27 */
angular.module("googleplus", []).provider("GooglePlus", [function () {
    var a = {};
    a.clientId = null, this.setClientId = function (b) {
        return a.clientId = b, this
    }, this.getClientId = function () {
        return a.clientId
    }, a.apiKey = null, this.setApiKey = function (b) {
        return a.apiKey = b, this
    }, this.getApiKey = function () {
        return a.apiKey
    }, a.scopes = "https://www.googleapis.com/auth/plus.login", this.setScopes = function (b) {
        return a.scopes = b, this
    }, this.getScopes = function () {
        return a.scopes
    }, this.init = function (b) {
        angular.extend(a, b)
    }, this.enableServerSide = function () {
        a.accessType = "offline", a.responseType = "code token id_token gsession"
    }, this.disableServerSide = function () {
        delete a.accessType, delete a.responseType
    }, this.$get = ["$q", "$rootScope", "$timeout", function (b, c, d) {
        var e, f = function () {
        };
        return f.prototype.login = function () {
            e = b.defer();
            var c = {
                client_id: a.clientId,
                scope: a.scopes,
                immediate: !1
            };
            return a.accessType && a.responseType && (c.access_type = a.accessType, c.response_type = a.responseType), gapi.auth.authorize(c, this.handleAuthResult), e.promise
        }, f.prototype.checkAuth = function () {
            gapi.auth.authorize({
                client_id: a.clientId,
                scope: a.scopes,
                immediate: !0
            }, this.handleAuthResult)
        }, f.prototype.handleClientLoad = function () {
            gapi.client.setApiKey(a.apiKey), gapi.auth.init(function () {
            }), d(this.checkAuth, 1)
        }, f.prototype.handleAuthResult = function (a) {
            a && !a.error ? (e.resolve(a), c.$apply()) : e.reject("error")
        }, f.prototype.getUser = function () {
            var a = b.defer();
            return gapi.client.load("oauth2", "v2", function () {
                gapi.client.oauth2.userinfo.get().execute(function (b) {
                    a.resolve(b), c.$apply()
                })
            }), a.promise
        }, f.prototype.getToken = function () {
            return gapi.auth.getToken()
        }, f.prototype.setToken = function (a) {
            return gapi.auth.setToken(a)
        }, f.prototype.logout = function () {
            gapi.auth.signOut()
        }, new f
    }]
}]).run([function () {
    var a = document.createElement("script");
    a.type = "text/javascript", a.async = !0, a.src = "https://apis.google.com/js/client.js";
    var b = document.getElementsByTagName("script")[0];
    b.parentNode.insertBefore(a, b)
}]);